   <template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <b-row>
                                <b-col lg="2" sm="12">
                                <p class="text-dark">{{ $t('irri_pump_main.complain_id') }} :</p>
                                </b-col>
                                <b-col lg="10" sm="12">
                                    <p class="text-dark">{{ pumpComplainId }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="2" sm="12">
                                <p class="text-dark">{{ $t('irri_pump_main.complained_by') }} :</p>
                                </b-col>
                                <b-col lg="10" sm="12">
                                    <p class="text-dark">{{ pumpComplainBy }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form @submit.prevent="handleSubmit(store)" @reset.prevent="reset" >
                                            <b-row>
                                                <b-col lg="6">
                                                    <ValidationProvider name="Division" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="division"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('irri_pump_main.division') }} <span class="text-danger">*</span>
                                                            </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="complainEquipment.division_id"
                                                            :options="divisionList"
                                                            id="division_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6">
                                                    <ValidationProvider name="District" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="district"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                                                            </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="complainEquipment.district_id"
                                                            :options="districtList"
                                                            id="district_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6">
                                                    <ValidationProvider name="Upazila" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="upazila"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                                                            </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="complainEquipment.upazilla_id"
                                                            :options="upazilaList"
                                                            id="upazila_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6">
                                                    <ValidationProvider name="Union" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="union_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('irri_pump_main.union') }} <span class="text-danger">*</span>
                                                            </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="complainEquipment.union_id"
                                                            :options="unionList"
                                                            id="unino_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col lg="6">
                                                    <ValidationProvider name="Mauza No" vid="mauza_no" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="mauza_no"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('irri_pump_main.mauza_no') }} <span class="text-danger">*</span>
                                                            </template>
                                                        <b-form-input
                                                            plain
                                                            v-model="complainEquipment.mauza_no"
                                                            id="mauza_no"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6">
                                                    <ValidationProvider name="JL No" vid="jl_no" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="jl_no"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('irri_pump_main.jl_no') }} <span class="text-danger">*</span>
                                                            </template>
                                                        <b-form-input
                                                            plain
                                                            v-model="complainEquipment.jl_no"
                                                            id="jl_no"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6">
                                                    <ValidationProvider name="Plot No" vid="plot_no" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="plot_no"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('irri_pump_main.plot_no') }} <span class="text-danger">*</span>
                                                            </template>
                                                        <b-form-input
                                                            plain
                                                            v-model="complainEquipment.plot_no"
                                                            id="plot_no"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <p style="font-weight:bold; color:gray; text-transform:capitalize">{{ $t('irri_pump_main.add_trouble_equipment') }}</p>
                                            <hr style="margin-top:-15px">
                                            <b-row>
                                                <b-col lg="2">
                                                    <p class="text-dark">{{ $t('irri_pump_main.equipment_name_en') }} <span class="text-danger">*</span></p>
                                                </b-col>
                                                <b-col lg="3">
                                                    <p class="text-dark ml-1">{{ $t('irri_pump_main.equipment_name_bn') }} <span class="text-danger">*</span></p>
                                                </b-col>
                                                <b-col lg="2">
                                                    <p class="text-dark">{{ $t('irri_pump_main.notef') }} <span class="text-danger">*</span></p>
                                                </b-col>
                                                <b-col lg="3">
                                                    <p class="text-dark ml-2">{{ $t('irri_pump_main.note_bn') }} <span class="text-danger">*</span></p>
                                                </b-col>
                                                <b-col lg="2">
                                                    <p class="text-dark">{{ $t('irri_pump_main.action') }}</p>
                                                </b-col>
                                            </b-row>
                                            <b-row v-for="(detail,index) in complainEquipment.details" :key="index">
                                                <b-col lg="2" sm="4">
                                                    <ValidationProvider name="Name (En)" rules="required">
                                                        <b-form-group
                                                            class="row ml-1"
                                                            label-for="name"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <b-form-input
                                                                plain
                                                                id="name"
                                                                v-model="detail.name"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="3" sm="4">
                                                    <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                                                        <b-form-group
                                                            class="row ml-1"
                                                            label-for="name_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <b-form-input
                                                                plain
                                                                id="name_bn"
                                                                v-model="detail.name_bn"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="2" sm="4">
                                                    <ValidationProvider name="Note (En)" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-for="note"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <b-form-input
                                                                plain
                                                                id="note"
                                                                v-model="detail.note"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="3" sm="4">
                                                    <ValidationProvider name="Note (Bn)" vid="note_bn" rules="required">
                                                        <b-form-group
                                                            class="row ml-1"
                                                            label-for="note_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <b-form-input
                                                                plain
                                                                id="note_bn"
                                                                v-model="detail.note_bn"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="2" sm="4">
                                                    <b-button class="btn btn-outline-danger" v-if="index !== 0" variant=" iq-bg-danger" size="sm" @click.prevent="removeItem(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                                    <b-button class="btn btn-outline-success" v-if="index === 0" variant=" iq-bg-success" size="sm" @click="addMore"><i class="ri-add-line m-0"></i></b-button>
                                                </b-col>
                                            </b-row>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpComplTroEquip } from '../../api/routes'

export default {
    name: 'FormLayout',
    props: ['id', 'farmer_id', 'complainId', 'complainBy'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.$t('globalTrans.save'),
            complainEquipment: {
                complain_id: this.$props.id,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                union_id: 0,
                pump_id: '',
                mauza_no: '',
                jl_no: '',
                plot_no: '',
                details: [
                    {
                        name: '',
                        name_en: '',
                        note: '',
                        note_bn: ''
                    }
                ]
            },
            pumpComplainId: '',
            pumpComplainBy: '',
            districtList: [],
            upazilaList: [],
            unionList: []
        }
    },
    created () {
        this.pumpComplainId = this.$props.complainId
        this.pumpComplainBy = this.$props.complainBy
    },
    computed: {
        divisionList: function () {
            return this.$store.state.commonObj.divisionList
        }
    },
    watch: {
        'complainEquipment.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'complainEquipment.district_id': function (newVal, oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'complainEquipment.upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
        }
    },
    methods: {
        async store () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: false }
            result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpComplTroEquip, this.complainEquipment)
            loadinState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadinState)
            this.loading = false
            if (result.success) {
                this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: 'Success',
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-1')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnionList (upazillaId = null) {
            const unionList = this.$store.state.commonObj.unionList
            if (upazillaId) {
                return unionList.filter(union => union.upazilla_id === upazillaId)
            }
            return unionList
        },
        addMore () {
            const tempItem = {
                name: '',
                name_en: '',
                note: '',
                note_bn: ''
            }
            this.complainEquipment.details.push(tempItem)
        },
        removeItem (index) {
            this.complainEquipment.details.splice(index, 1)
        }
    }
}
</script>
