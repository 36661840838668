<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-table-simple striped small bordered hover>
                <b-tr>
                  <b-th>{{ $t('externalUserIrrigation.subject') }}</b-th>
                  <b-td colspan="3">{{ (this.$i18n.locale === 'bn') ? complain.subject_bn : complain.subject }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>{{ $t('pump_install.organization') }}</b-th>
                  <b-td colspan="3">{{ getOrganization(complain.org_id) }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>{{ $t('externalUserIrrigation.complain_id') }}</b-th>
                  <b-td>{{ complain.complain_id }}</b-td>
                  <b-th>{{ $t('pump_install.email') }}</b-th>
                  <b-td>{{ complain.email }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>{{ $t('pump_install.division') }}</b-th>
                  <b-td>{{ appHierarchyNames.divisionName }}</b-td>
                  <b-th>{{ $t('pump_install.district') }}</b-th>
                  <b-td>{{ appHierarchyNames.districtName }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>{{ $t('pump_install.upazila') }}</b-th>
                  <b-td>{{ appHierarchyNames.upazilaName }}</b-td>
                  <b-th>{{ $t('pump_install.union') }}</b-th>
                  <b-td>{{ appHierarchyNames.unionName }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>{{ $t('externalUserIrrigation.details') }}</b-th>
                  <b-td colspan="3"><p class="text-dark" v-html="(this.$i18n.locale === 'bn') ? complain.details_bn : complain.details"></p></b-td>
                </b-tr>
              </b-table-simple>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row v-else>
          <b-col lg="12" sm="12">
            <b-overlay :show="loader">
              <div class="text-dark text-center">
                Loading...
              </div>
          </b-overlay>
          </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpComplainDetails } from '../api/routes'
import HierarchycalDropdownNames from '@/Utils/common'
import ExportPdf from './export_pdf_details'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getComplainDetails()
      Object.freeze(tmp)
      this.complain = tmp
    }
  },
  data () {
    return {
      loader: true,
      appHierarchyNames: null,
      complain: '',
      base_url: irriSchemeServiceBaseUrl,
      org_name: ''
    }
  },
  computed: {
  },
  methods: {
    async getComplainDetails () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, `${pumpComplainDetails}/${this.$props.id}`)
      if (result.success) {
        this.complain = result.data
        this.appHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.complain.far_union_id, 'union')
      }
      this.loader = false
    },
    getOrganization (orgId) {
        const orgList = this.$store.state.commonObj.organizationProfileList
        const org = orgList.find(orgItem => orgItem.value === orgId)
        if (this.$i18n.locale === 'bn') {
          this.org_name = org.text_bn
          return org.text_bn
        } else {
          this.org_name = org.text_en
          return org.text_en
        }
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Complain Application' : 'অভিযোগের আবেদন'
      ExportPdf.exportPdfDetails(irriSchemeServiceBaseUrl, '/report-heading/detail', 3, reportTitle, this.complain, this.appHierarchyNames, this.org_name, this)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
