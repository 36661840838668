<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <b-row>
                                <b-col lg="2" sm="12">
                                <p class="text-dark">{{ $t('irri_pump_main.complain_id') }} :</p>
                                </b-col>
                                <b-col lg="10" sm="12">
                                    <p class="text-dark">{{ pumpComplainId }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="2" sm="12">
                                <p class="text-dark">{{ $t('irri_pump_main.complained_by') }} :</p>
                                </b-col>
                                <b-col lg="10" sm="12">
                                    <p class="text-dark">{{ pumpComplainBy }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form @submit.prevent="handleSubmit(store)" @reset.prevent="reset" >
                                            <b-row>
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="Division" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="3"
                                                            label-for="division"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                                                            </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="resunk.division_id"
                                                            :options="divisionList"
                                                            id="division_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="District" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="3"
                                                            label-for="district"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                                                            </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="resunk.district_id"
                                                            :options="districtList"
                                                            id="district_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="Upazila" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="3"
                                                            label-for="upazila"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                            plain
                                                            v-model="resunk.upazilla_id"
                                                            :options="upazilaList"
                                                            id="upazila_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="Pump Information Id" vid="pump_informations_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="3"
                                                            label-for="pump_informations_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('irri_pump_main.pump_id') }} <span class="text-danger">*</span>
                                                            </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="resunk.pump_informations_id"
                                                            id="pump_informations_id"
                                                            :options="pumpList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Resunk Note (En)" vid="resunk_note" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="3"
                                                            label-for="resunk_note"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                                <template v-slot:label>
                                                                    {{ $t('irri_pump_main.resunk_notef') }} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-textarea
                                                                    useCustomImageHandler
                                                                    id="resunk_note"
                                                                    v-model="resunk.resunk_note"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Resunk Note (Bn)" vid="resunk_note_bn">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="3"
                                                            :label="$t('irri_pump_main.resunk_note_bn')"
                                                            label-for="resunk_note_bn"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                                <b-form-textarea
                                                                    id="resunk_note_bn"
                                                                    v-model="resunk.resunk_note_bn"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpComplResunk, pumpList } from '../../api/routes'

export default {
    name: 'FormLayout',
    props: ['id', 'complainId', 'complainBy'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.$t('globalTrans.save'),
            resunk: {
                complain_id: this.$props.id,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                pump_informations_id: '',
                resunk_note: '',
                resunk_note_bn: ''
            },
            pumpComplainId: '',
            pumpComplainBy: '',
            districtList: [],
            upazilaList: [],
            pumpList: []
        }
    },
    computed: {
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        }
    },
    created () {
        this.pumpComplainId = this.$props.complainId
        this.pumpComplainBy = this.$props.complainBy
    },
     watch: {
        'resunk.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'resunk.district_id': function (newVal, oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'resunk.upazilla_id': function (newVal, oldVal) {
            // this.pumpList = this.getpumpList(newVal)
            this.getpumpList(newVal)
        }
    },
    methods: {
        async store () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: false }
            result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpComplResunk, this.resunk)
            loadinState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadinState)
            this.loading = false
            if (result.success) {
                this.$toast.success({
                    title: 'Success',
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-1')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getpumpList () {
            const searchPumpInfo = {
                division_id: this.resunk.division_id,
                district_id: this.resunk.district_id,
                upazilla_id: this.resunk.upazilla_id
            }
            RestApi.getData(irriSchemeServiceBaseUrl, pumpList, searchPumpInfo).then(response => {
                this.pumpList = response.data
            })
        }
    }
}
</script>
