import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, locationData, organizationName, vm) => {
  try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const pdfContent = [
           ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const allRowsHead = [
          [
            { text: vm.$t('externalUserIrrigation.subject'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? data.subject_bn : data.subject, style: 'td', alignment: 'left' }
          ],
          [
            { text: vm.$t('externalUserIrrigation.complain_id'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? vm.$n(data.complain_id) : data.complain_id, style: 'td', alignment: 'left' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['15%', '2%', '50%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        const allRowsHead2 = [
          [
            { text: vm.$t('pump_install.organization'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? organizationName : organizationName, style: 'td', alignment: 'left' },
            { text: vm.$t('pump_install.email'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? data.email : data.email, style: 'td', alignment: 'left' }
          ],
          [
            { text: vm.$t('pump_install.division'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? locationData.divisionName : locationData.divisionName, style: 'td', alignment: 'left' },
            { text: vm.$t('pump_install.district'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? locationData.districtName : locationData.districtName, style: 'td', alignment: 'left' }
          ],
          [
            { text: vm.$t('pump_install.upazila'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? locationData.upazilaName : locationData.upazilaName, style: 'td', alignment: 'left' },
            { text: vm.$t('pump_install.union'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? locationData.unionName : locationData.unionName, style: 'td', alignment: 'left' }
          ],
          [
            { text: vm.$t('externalUserIrrigation.details'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? data.details_bn : data.details, style: 'td', alignment: 'left' },
            { text: '', style: 'td', alignment: 'left' },
            { text: '', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? '' : '', style: 'td', alignment: 'left' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['15%', '2%', '25%', '8%', '10%', '30%'],
            body: allRowsHead2
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            th1: {
                fontSize: 9
              },
            td: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            search: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            fertilizer: {
              margin: [0, 10, 0, 7]
            },
            fertilizerSHeader: {
                fontSize: 10,
                margin: [40, 0, 0, 0]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 10,
              margin: [0, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 5]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            krishi: {
              margin: [0, 1, 0, 15],
              alignment: 'center'
            },
            header3: {
              fontSize: 9,
              margin: [0, 15, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
